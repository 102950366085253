:root {
  --spa-globals-top-banner-height: 0px;
}

aside[data-active-index='1'] {
  z-index: 7500;
  position: fixed !important;
  max-width: 27.5rem !important;
  width: 27.5rem !important;
  overflow: hidden !important;

  & > div:first-child {
    width: 27.5rem !important;
    max-height: 100% !important;
  }

  @media (max-width: 1200px) {
    max-height: 100dvh !important;
    border-radius: unset !important;
    height: 100% !important;

    div[id='scrollable-content'] {
      max-height: 100dvh !important;
    }
  }

  @media (max-width: 540px) {
    width: 100% !important;
    max-width: 100% !important;
    max-height: calc(100vh - 1.5rem) !important;
    border-top-right-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
    height: auto !important;

    & > div:first-child {
      width: 100% !important;
    }
  }
}

@primary-color: #2B64F5;
.eb_content_title {
  margin: 0 0 15px 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.eb_content_subtitle {
  margin: 0 0 15px 0;
  font-size: 1.125rem;
  font-weight: 300;
}

.eb_content .ant-alert.ant-alert-closable {
  padding-top: 30px;
}

@media (max-width: 536px) {
  .eb_content_subtitle_input {
    width: 200px;
  }
}
@media (max-width: 335px) {
  .eb_content_subtitle_btn {
    margin-left: -20px;
  }
}

@primary-color: #2B64F5;
#FillOrgStructureContainer.content {
  width: 100%;
}
#FillOrgStructureContainer.content .panel-spaced {
  padding-top: 38px;
  padding-bottom: 60px;
  padding-right: 50px;
}
#FillOrgStructureContainer.content .panel {
  padding: 15px 30px 15px 35px;
}
#FillOrgStructureContainer.content .panel-shadow {
  box-shadow: 0 10px 40px 0px #eaeaea;
}
#FillOrgStructureContainer.content .panel {
  background: #fff;
  padding: 15px 20px;
  margin: 0 0 20px 0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.owners-form {
  margin: 0 0 60px 0;
}
.owners {
  line-height: 1.4;
}
.owners .top {
  display: flex;
}
.owners .left {
  position: relative;
  width: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.owners .dot-company {
  background: #cabcfd;
}
.owners .dot-person {
  background: #9be4fe;
}
.owners .dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 31px;
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #9be4ff;
  margin: 0 10px 0 0;
  display: inline-block;
  position: absolute;
}
.owners .corner {
  width: 18px;
  height: 65px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom-left-radius: 8px;
  position: absolute;
  left: 7px;
  top: 100%;
  margin-top: 5px;
}
.owners .line {
  height: 52px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom-left-radius: 8px;
  position: absolute;
  left: 7px;
  top: 100%;
  margin-top: 5px;
}
.owners .outside {
  left: auto;
  right: 100%;
  margin-right: 5px;
  height: 90px;
  top: auto;
  bottom: 7px;
}
.owners .middle {
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  padding: 13px 0 0 0;
}
.owners .owners-name {
  font-size: 16px;
  padding-top: 5px;
}
.owners .owners-type {
  font-size: 12px;
  color: #b2b2b2;
}
.owners .percent {
  color: #b2b2b2;
}
.owners .right {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 0 auto;
}
.owners .add {
  font-size: 12px;
  color: #7154d6;
  cursor: pointer;
  margin: auto 0 19px 0;
}
.owners .owner-inputs {
  width: 100%;
}
.subowners {
  margin-left: 30px;
}
.subsubowners {
  margin-left: 60px;
}
.subsubsubowners {
  margin-left: 90px;
}
.subsubsubsubowners {
  margin-left: 120px;
}
.subsubsubsubsubowners {
  margin-left: 150px;
}
.subsubsubsubsubsubowners {
  margin-left: 180px;
}
.subsubsubsubsubsubsubowners {
  margin-left: 195px;
}
.subsubsubsubsubsubsubsubowners {
  margin-left: 210px;
}
.subsubsubsubsubsubsubsubsubowners {
  margin-left: 225px;
}
.subsubsubsubsubsubsubsubsubsubowners {
  margin-left: 240px;
}
.subsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 255px;
}
.subsubsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 270px;
}
.subsubsubsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 280px;
}
.subsubsubsubsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 290px;
}
.subsubsubsubsubsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 300px;
}
.subsubsubsubsubsubsubsubsubsubsubsubsubsubsubsubowners {
  margin-left: 310px;
}
.owner-inputs .form-field-wrapper.big-input .form-field-title {
  top: 3px;
  font-size: 12px;
  left: 32px;
}
.sme .eb_content_wrapper .owner-inputs .form-field-wrapper {
  margin-bottom: 0;
}
#orgStructureForm.owner-inputs select {
  padding: 12px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 100%;
}
.sme .eb_content_wrapper #FillOrgStructureContainer .owner-inputs .ant-input {
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5715;
  padding: 19px 12px 5px 18px;
}
#FillOrgStructureContainer .owner-inputs .form-field-wrapper.big-input .form-field-title {
  left: 16px;
}
#orgStructureForm .ant-select-dropdown {
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 15px;
  width: 200px;
}
#orgStructureForm .form-field-wrapper.big-input.big-input-empty .form-field-title {
  font-size: 14px;
  top: 14px;
}
.btn-big {
  padding: 17px 30px;
  border-radius: 26px;
  font-size: 18px;
  width: 134px;
}
#FillOrgStructureContainer *,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}
.btn-group-right {
  justify-content: flex-end;
}
.btn-group {
  display: flex;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: none;
}
@media screen and (max-width: 580px) {
  .file-org-btn-container {
    justify-content: space-between;
  }
}
@media screen and (max-width: 580px) {
  .org-structure-form-col {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
@media screen and (max-width: 580px) {
  .org-btn-container {
    margin: 16px 0 16px 8px;
  }
}
@media screen and (max-width: 580px) {
  .org-structure-form-select-row {
    grid-gap: 16px;
    gap: 16px;
  }
}
.check-icon svg {
  width: 24px;
  height: 20px;
  fill: #00a500;
}

.eb_content_title {
  margin: 0 0 15px 0;
  font-size: 1.5rem;
  font-weight: 300;
}
.eb_content_subtitle {
  margin: 0 0 15px 0;
  font-size: 1.125rem;
  font-weight: 300;
}
.eb_content .ant-alert.ant-alert-closable {
  padding-top: 30px;
}
@media (max-width: 536px) {
  .eb_content_subtitle_input {
    width: 200px;
  }
}
@media (max-width: 335px) {
  .eb_content_subtitle_btn {
    margin-left: -20px;
  }
}

.eb_top {
  background: #f3f3f3;
}

:root {
  --spa-globals-top-banner-height: 0px;
}
aside[data-active-index='1'] {
  z-index: 7500;
  position: fixed !important;
  max-width: 27.5rem !important;
  width: 27.5rem !important;
  overflow: hidden !important;
}
aside[data-active-index='1'] > div:first-child {
  width: 27.5rem !important;
  max-height: 100% !important;
}
@media (max-width: 1200px) {
  aside[data-active-index='1'] {
    max-height: 100dvh !important;
    border-radius: unset !important;
    height: 100% !important;
  }
  aside[data-active-index='1'] div[id='scrollable-content'] {
    max-height: 100dvh !important;
  }
}
@media (max-width: 540px) {
  aside[data-active-index='1'] {
    width: 100% !important;
    max-width: 100% !important;
    max-height: calc(100vh - 1.5rem) !important;
    border-top-right-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
    height: auto !important;
  }
  aside[data-active-index='1'] > div:first-child {
    width: 100% !important;
  }
}

